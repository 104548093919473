import React, { useEffect, useState } from 'react';
import { FiFacebook } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import { firestore } from '../../firebase';
import './Footer.css';
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const [workingHours, setWorkingHours] = useState({
    weekdays_open: '08:00 am',
    weekdays_close: '12:00 am',
    weekend_open: '07:00 am',
    weekend_close: '11:00 pm',
    phone: '+40 256 212 345',
    phone2: '+40 741 234 564',
    email: 'cateringdum@gmail.com',
    whatsapp: '+40 755 891 265',
  });

  useEffect(() => {
    const fetchWorkingHours = async () => {
      const settingsDocRef = doc(firestore, 'settings', 'working_hours');
      const docSnap = await getDoc(settingsDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setWorkingHours(data);
      } else {
      }
    };

    fetchWorkingHours();
  }, []);

  const formatPhone = (phone) => phone.replace(/\s+/g, '');

  return (
    <div className="app__footer section__padding" id="login">
      <FooterOverlay />
      <Newsletter />

      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">{t('footer.contactUs')}</h1>
          <p className="p__opensans">{t('footer.address')}</p>
          <p className="p__opensans">{workingHours.phone}</p>
          <p className="p__opensans">{workingHours.phone2}</p>
          <p className="p__opensans" style={{ textTransform: 'none' }}>{workingHours.email}</p>
        </div>

        <div className="app__footer-links_logo">
          <img src={images.genesa} alt="footer_logo" />
          <p className="p__opensans">&quot;{t('footer.quote')}&quot;</p>
          <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
          <div className="app__footer-links_icons">
            <a href="https://www.facebook.com/restaurantgenesa" target="_blank" rel="noopener noreferrer"><FiFacebook /></a>
            <a href={`https://wa.me/${formatPhone(workingHours.whatsapp)}`} target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
          </div>
        </div>

        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">{t('footer.workingHours')}</h1>
          <p className="p__opensans">{t('footer.weekdays')}</p>
          <p className="p__opensans">{`${workingHours.weekdays_open} - ${workingHours.weekdays_close}`}</p>
          <p className="p__opensans">{t('footer.weekends')}</p>
          <p className="p__opensans">{`${workingHours.weekend_open} - ${workingHours.weekend_close}`}</p>
        </div>
      </div>

      <div className="footer__legal-links">
        <a href="/terms" className="p__opensans">Termeni și condiții</a> |
        <a href="/privacy" className="p__opensans">Politica de confidențialitate</a> |
        <a href="/cancellation" className="p__opensans">Politica de anulare sau retur</a>
      </div>

      <div className="footer__anpc-links">
        <div className="anpc-item">
          <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank" rel="noopener noreferrer">
            <img src={images.anpc} alt="A.N.P.C" className="anpc-logo" />
          </a>
          <p className="p__opensans">A.N.P.C</p>
        </div>
        <div className="anpc-item">
          <a href="https://anpc.ro/ce-este-sal" target="_blank" rel="noopener noreferrer">
            <img src={images.anpc_sal} alt="A.N.P.C - SAL" className="anpc-logo" />
          </a>
          <p className="p__opensans">A.N.P.C - SAL</p>
        </div>
      </div>

      <div className="footer__copyright">
        <p className="p__opensans">{t('footer.copyright')}</p>
      </div>
    </div>
  );
};

export default Footer;
