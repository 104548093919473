import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const MenuQR = () => {
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const fileRef = ref(storage, 'menu/menu.pdf');
        const url = await getDownloadURL(fileRef);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF URL:', error);
      }
    };

    fetchPdfUrl();
  }, []);

  return (
    <div className="menu-qr-container">
      {pdfUrl ? (
        <Worker workerUrl="/pdf.worker.min.js">
          <div style={{ height: '800px' }}>
            <Viewer fileUrl={pdfUrl} />
          </div>
        </Worker>
      ) : (
        <p>Loading menu...</p>
      )}
    </div>
  );
};

export default MenuQR;
